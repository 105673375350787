import axios from 'axios';
import { Abstract } from './Abstract';

export class InventoryArticle extends Abstract 
{ 
  constructor(session) 
  { 
    super('Inventory/InventoryArticle', session);
    this.barcode = null;
    this.name = null;
    this.key_name = null;
    this.id_inventory_warehouse = null;
    this.id_inventory_subwarehouse = null;
    this.id_inventory_measure_unit = null;
    this.id_inventory_family = null;
    this.id_inventory_group = null;
    this.id_inventory_subgroup = null;
    this.unit_price = 0.00;
  }

  async getCustom() {
    let response = await axios.get(this.controller + "/getCustom", {
        headers: {
            usuario: this.usuario,
            empresa: this.intEmpresa,
            sucursal: this.intSucursal
        }
    });
    return response.data;
  }
}
 