import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class Quotation extends Abstract {
    constructor(session) {
        super('crm/Quotation', session);
        this.id_crm_prospect = null;
        this.date = new Date();
        this.expiration_date = new Date();
        this.key_name = null;
        this.total = 100.00;
        this.is_dealed = false;
        this.items = [];
        this.orders = [];
        this.status = null;
        this.id_customer = null;
        this.customer_name = null;
        this.customer_rfc = "XAXX010101000";
        this.customer_address = "Mitras Centro, 64460 Monterrey, N.L. MITRAS, MONTERREY, NUEVO LEON, México";
        this.customer_email = null;
        this.customer_phone = null;
        this.type_of_sale = null;
        this.commercial_business = null;
        this.list = null;
        this.desc_g1 = 0.00;
        this.desc_g2 = 0.00;
        this.desc_g3 = 0.00;
        this.iva = null;
        this.ieps = null;
        this.change_type = null;
        this.coin = null;
        this.options = null;
        this.id_request = null;
    }

    async setOption(id) {
        let response = await axios.put(this.controller + '/options/' + id, {
            
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
 
    async list(params) { 
        let response = await axios.post(this.controller + '/data', params, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async pedido(status, orders) {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
        let response = await axios.put(this.controller + '/pedido/' + this.id, {
            status: status,
            orders: orders
        }, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async cancelar() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
            console.log('Viva Mexico:');
        let response = await axios.put(this.controller + '/cancelar/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}