<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Dialog v-model:visible="prospectDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <ProspectUI @update="updateProspect" @save="saveProspect" :modal="true" />
        </Dialog>
        <Dialog v-model:visible="productDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
        </Dialog>
        <Dialog v-model:visible="customerDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <CustomerUI @save="saveCustomer" :isModal="true" />
        </Dialog>
        <HelperDialog v-model="prospectHelper" header="Buscar Prospecto" :headers="prospectHeaders" :rows="prospects" @selected="selectProspect" />
        <HelperDialog v-model="productHelper" header="Buscar Producto" :headers="productHeaders" :rows="products" @selected="selectProduct" />
        <HelperDialog v-model="quotationHelper" header="Buscar Cotizacion" :headers="quotationHeaders" :rows="quotations" @selected="selectQuotation" />
        <HelperDialog v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers" @selected="selectCustomer" />
        <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false" @deleted="confirmDelete" />
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <Panel header="Cotización">
                    <BasicFormToolbar v-if="!view" @new="openNew" @save="save"
                        :actions="modal ? ['new', 'save', 'refresh'] : ['new', 'save'] " />
                <div class="grid">
                  <div class="col-12">
                    <Fieldset legend="Parametros">
                    <div class="grid formgrid p-fluid">
                        <FormInputText :valid="validate.validations.id" @search="(quotationHelper.visible = true)" :readonly="true" :search="true" wrapperClass="field col-2" label="Folio" ref="nombre" v-model="entity.id" />
                        <FormDropdownComplex :valid="validate.validations.opcion" :labelInOption="'option'" :labelInValue="'option'" :wrapperClass="'field col-2'" label="Opcion" v-model="entity.options" :options="option" optionLabel="option" optionValue="option" />
                        <FormCalendar :valid="validate.validations.date" wrapperClass="field col-2" label="Fecha" ref="nombre" v-model="entity.date" />
                        <FormCalendar :valid="validate.validations.expiration_date" wrapperClass="field col-2" label="Fecha Expiración" ref="nombre" v-model="entity.expiration_date" />
                        <Status :image="status.image" :severity="status.severity" :status="status.status" />
                      </div>
                      </Fieldset>
                  </div>
                  </div>
                    <div class="grid formgrid p-fluid">
                        <div class="col-5">
                            <Fieldset :toggleable="true" legend="Datos Facturacion">
                                <div class="p-fluid formgrid grid">
                                <FormInputText :valid="validate.validations.id_crm_prospect" wrapperClass="field col-6" style="display:none" label="" ref="nombre" v-model="entity.id_crm_prospect" />
                                <FormInputText v-if="!is_customer" :valid="validate.validations.prospect_name" :add="true" @add="(prospectDialog = true)" @search="(prospectHelper.visible = true)" :readonly="true" :search="true" wrapperClass="field col-6" label="Prospecto" v-model="entity.prospect_name" ></FormInputText>
                                <FormInputText v-if="is_customer" :valid="validate.validations.customer_name" :add="true" @add="(customerDialog = true)" @search="(customerHelper.visible = true)" :readonly="true" :search="true" wrapperClass="field col-6" label="Cliente" ref="nombre" v-model="entity.customer_name" />
                                <FormInputSwitch label="¿Es cliente?" :wrapperClass="'field col-4'" v-model="is_customer"/>
                                <FormInputText :valid="validate.validations.id_customer" :textArea="true" wrapperClass="field col-6" style="display:none" label="Clave" v-model="entity.id_customer" ></FormInputText>
                                <FormInputText wrapperClass="field col-6" label="RFC" :valid="validate.validations.rfc" v-model="entity.customer_rfc" />
                                <FormInputText wrapperClass="field col-6" label="Direccion" :valid="validate.validations.customer_address" v-model="entity.customer_address" />
                                <FormInputText wrapperClass="field col-6" label="E-mail" :valid="validate.validations.email" v-model="entity.customer_email" />
                                <FormInputText wrapperClass="field col-6" label="Telefono" :valid="validate.validations.phone" v-model="entity.customer_phone" />
                                </div>
                            </Fieldset>
                        </div>
                        <div class="col-6">
                        <Fieldset :toggleable="true" legend="Datos Comerciales">
                        <div class="p-fluid formgrid grid">
                                <FormDropdownComplex :valid="validate.validations.type_of_sale" :labelInOption="'type_of_sale'" :labelInValue="'type_of_sale'" :wrapperClass="'field col-4'" label="Tipo Venta" v-model="entity.type_of_sale" :options="type_of_sale" optionLabel="type_of_sale" optionValue="type_of_sale" />
                                <FormDropdownComplex :valid="validate.validations.commercial_business" :labelInOption="'commercial_business'" :labelInValue="'commercial_business'" :wrapperClass="'field col-4'" label="Giro Comercial" v-model="entity.commercial_business" :options="commercial_business" optionLabel="commercial_business" optionValue="commercial_business" />
                                <FormDropdownComplex :valid="validate.validations.list" :labelInOption="'list'" :labelInValue="'list'" :wrapperClass="'field col-4'" label="Lista" v-model="entity.list" :options="list" optionLabel="list" optionValue="list" />
                                <FormInputNumber v-model="entity.desc_g1" inputId="percent" prefix="%" label="% Desc.G1" :wrapperClass="'field col-4'" />
                                <FormInputNumber v-model="entity.desc_g2" inputId="percent" prefix="%" label="% Desc.G2" :wrapperClass="'field col-4'" />
                                <FormInputNumber v-model="entity.desc_g3" inputId="percent" prefix="%" label="% Desc.G3" :wrapperClass="'field col-4'" />
                                <FormDropdownComplex :labelInOption="'iva'" :labelInValue="'iva'" :wrapperClass="'field col-4'" label="Iva" v-model="entity.iva" :options="iva" optionLabel="% IVA" optionValue="iva" />
                                <FormDropdownComplex :labelInOption="'c_Moneda - Descripcion'" :labelInValue="'c_Moneda'" :wrapperClass="'field col-4'" label="Moneda" v-model="entity.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" />
                                <FormInputNumber :valid="validate.validations.change_type" :mode="'currency'" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-4" label="T.Cambio" v-model="entity.change_type" />
                            </div>
                        </Fieldset>
                        </div>
                        <div class="col-12">
                            <Fieldset :toggleable="true" legend="Opciones">
                                <div class="grid formgrid p-fluid" id="dialog">
                                    <div class="col-3">
                                      <Button v-on:click="opciones" label="Generar Opcion" class="p-button-warning mr-2" />
                                    </div>
                                    <div class="col-3">
                                        <Button @click="convertirPedido" label="Convertir a Pedido" class="p-button-warning mr-2" />
                                    </div>
                                    <div class="col-3">
                                        <Button @click="copy" label="Copiar" class="p-button-warning mr-2" />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div class="col-12"> 
                            <br>
                            <Fieldset :toggleable="true" legend="Articulos">
                                <BasicFormToolbar @new="openNew" @agregar="saveDetail" 
                                :actions="['new', 'agregar']" />
                                <div class="p-fluid formgrid grid">
                                    <FormInputNumber ref="quantity" :valid="validateItem.validations.quantity" wrapperClass="field col-2" label="Cantidad" :min="1" v-model="item.quantity"/>
                                    <FormInputText @enter="(productHelper.visible = true)" @search="(productHelper.visible = true)" @add="(productDialog = true)" :add="false" :valid="validateItem.validations.description" wrapperClass="field col-2" label="Articulo" v-model="item.description" :search="true" />
                                    <FormInputNumber :valid="validateItem.validations.price" :mode="'currency'" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-2" label="P.U." v-model="item.price" />
                                    <FormInputNumber :valid="validateItem.validations.iva" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-2" label="I.V.A." v-model="item.iva" :prefix="'%'"/>
                                    <FormInputNumber :valid="validateItem.validations.ieps" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-2" label="I.E.P.S." v-model="item.ieps" :prefix="'%'"/>
                                    <FormInputNumber wrapperClass="field col-2" label="IVA Trasladado" v-model="item.iva" :min="0" :max="1"   :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'" :prefix="'%'" />
                                    <FormInputNumber @enter="saveDetail" @tab="saveDetail" :mode="'currency'" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-2" label="Total" v-model="item.total" :disabled="true" />
                                    <div class="col-12">
                                        <BasicDatatable 
                                        @selected="selectItem"
                                        @deleted="deleteItem"
                                        @edited="editItem"
                                        :dataKey="'id_number'"
                                        :selectionMode="'single'"
                                        :rowaction="true"
                                        :rowedit="true"
                                        :rowdelete="true"
                                        :headers="itemHeaders" 
                                        :headerVisible="true"
                                        :rows="entity.items" /> 
                                    </div>
                                    <FormInputText :textArea="true" v-model="entity.comment" label="Commentarios" wrapperClass="field col-12 xl:col-3" :valid="validate.validations.comment" />
                                    <FormInputText :textArea="true" v-model="entity.comment2" label="Commentarios 2" wrapperClass="field col-12 xl:col-3" :valid="validate.validations.comment2" />
                                </div>
                            </Fieldset>
                        </div>
                        <Dialog
                            v-model:visible="newDialog"
                            :style="{ width: '1200px' }"
                            :modal="true">
                            <div v-if="!modal" class="col-12">
                                <br>
                                <Fieldset :toggleable="true" legend="Registros">
                                    <BasicDatatable :rows="quotations" :headers="quotationHeaders" />
                                </Fieldset>
                            </div>
                        </Dialog>
                <ConfirmDialog 
                    @closed="confirmDialog = false" 
                    v-model="confirmDialog" 
                    @confirm="generateOption" 
                    />
                    <ConfirmDialog
                    @closed="confirmDialog = false"
                    v-model="confirmDialog"
                    @confirm="pedido"
                    />
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>
  
<script>

import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";

import { Prospect } from "../../../models/crm/Prospect";
import { Quotation } from "../../../models/crm/Quotation";
import { QuotationItem } from "../../../models/crm/QuotationItem";
import ProspectUI from '../Catalogos/Prospecto';
import CustomerUI from '../../Comercial/Catalogos/Customer';
import { Customer } from '../../../models/comercial/Customer';
import { InventoryArticle } from '../../../models/inventarios/InventoryArticle';
import ConfirmDialog from '../../../components/general/ConfirmDialog';
import Status from "../../../components/general/Status.vue";
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';
import { Order } from "../../../models/comercial/Order";

import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
    Toast,
    ErrorToast,
    satCatalogo,
} from "../../../utilities/General";

import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';

export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null
    },
    data() {
        return {
            el: 'dialog',
            mensaje: 'Generar opcion',
            entity: [],
            is_customer: false,
            prospectDialog: false,
            productDialog: false,
            customerDialog: false,
            quotations: [],
            prospects: [],
            products: [],
            customers: [],
            c_Moneda: [],
            orders: [],
            deleteDialog: false,
            confirmDialog: false,
            newDialog: false,
            item: new QuotationItem(),
            order: new Order(),
            rules: [
                new Rule({ name: "date" }),
                new Rule({ name: "expiration_date" })
            ],
            validate: {
                valid: false,
                validations: {
                    date: null,
                    expiration_date: null,
                    key_name: null
                },
            },
            rulesItem: [
                new Rule({ name: "description" }),
                new Rule({ name: "quantity" }),
                new Rule({ name: "price" })
            ],
            validateItem: {
                valid: false,
                validations: {
                    description: null,
                    quantity: null,
                    price: null
                },
            },
            customersHeaders: [
                new HeaderGrid('Nombre', 'name'), 
                new HeaderGrid('RFC', 'rfc')
            ],
            productHeaders: [
                new HeaderGrid("Clave", "key_name"),
                new HeaderGrid("Nombre", "name"),
                new HeaderGrid("Precio", "unit_price", { type: 'currency' })
            ],
            itemHeaders: [
                new HeaderGrid("SKU", "id_number"),
                new HeaderGrid("Cantidad", "quantity"),
                new HeaderGrid("Description", "description"),
                new HeaderGrid("P.U.", "price", { type: 'currency' }),
                new HeaderGrid("SubTotal", "subtotal", { type: 'currency', function: 'SUM', formula: 'evaluate', expression: 'price * quantity'}),
                new HeaderGrid('IVA', 'iva' , { type: 'percentage'}) , 
                new HeaderGrid("Total", "total", { type: 'currency', function: 'SUM'}),
            ],
            prospectHeaders: [
                new HeaderGrid("ID", "id"),
                new HeaderGrid("Nombre", "prospect_name")
            ],
            quotationHeaders: [
                new HeaderGrid("Cotizacion", "id"),
                new HeaderGrid("Fecha", "date", { type: 'date' }),
                new HeaderGrid("Nombre Cliente", "customer_name"),
            ],
            quotationHelper: {
                visible: false
            },
            prospectHelper: {
                visible: false,
            },
            productHelper: {
                visible: false,
            },
            customerHelper: {
                visible: false,
            },
            option: [
                { option: 'A' },
                { option: 'B' },
                { option: 'C' },
                { option: 'D' },
                { option: 'E' },
                { option: 'F' },
                { option: 'G' },
            ],
            type_of_sale: [
                { type_of_sale: 'General'},
                { type_of_sale: 'Venta Cliente'},
            ],
            commercial_business: [
                { commercial_business: 'Seleccione..'},
                { commercial_business: 'Publico en General'},
            ],
            list: [
                { list: 'Lista 1'},
                { list: 'Lista 2'},
                { list: 'Lista 3'},
                { list: 'Lista 4'},
            ],
            iva: [
                { iva: '16.00'},
                { iva: '8.00'},
                { iva: '0.00'}
            ],
            loading: false,
        };
    },
    components: { ProspectUI, CustomerUI, HelperDialog, BasicDatatable, FormCalendar,FormInputNumber, DeleteDialog, Loader, FormInputText, BasicFormToolbar, FormDropdownComplex, ConfirmDialog, Status, FormInputSwitch },
    created() {
        this.entity = new Quotation(this.session);
    },
    async mounted() {
        await this.refresh();
        this.c_Moneda = await satCatalogo(9);
        this.is_customer = false;
    },
    computed: {
        status(){
      if (this.entity.status == 1) {
        return {
          severity: 'success',
          status: 'REGISTRADO',
          image: "afected.png"
        } 
      } else if (this.entity.status == 2) {
        return {
          severity: 'warning',
          status: 'Sin pedido',
          image: "processing.png"
        } 
      } else if (this.entity.status == 3) {
        return {
            severity: 'success',
            status: 'Con Pedido',
            image: "authorized.png"
        }
      } else if (this.entity.status == 9) {
        return {
            severity: 'danger',
            status: 'Cancelado',
            image: "cancel.png"
        }
      } 
      else {
        return {
          severity: 'primary',
          status: 'Sin Registrar',
          image: "ban.svg"
        } 
      }
    }
    },
    watch: {
        ["item.price"](newValue) {
            this.item.total = newValue * this.item.quantity;
        },
        ["item.quantity"](newValue) {
            this.item.total = newValue * this.item.price;
        },
        ["entity.currency"](currency) {
            if (currency == "MXN") {
            this.entity.change_type = "1.00"
      }
    },
        ["item.iva"](newValue) {
            this.item.iva = newValue * this.entity.iva;
        }
    },
    methods: {
        opciones: async function (event) {
            alert('Opcion ' + this.mensaje + '!')
            this.loading = true;
            try {
                if (this.entity.id == 0) {
                    throw "Seleccione una cotizacion"
                }
                alert('Generar Opcion' + this.options)
                this.entity.id = null;
                    let entity = await this.entity.save();
                    this.$emit('save', entity);
                    this.quotations.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Se genero la alternativa: " + this.option,
                        })
                    );
                this.confirmDialog = false;
            }
            catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            if (event) {
                alert(event.target.tagName)
            }
        },
        saveConcept(payload) {
            this.products.push(payload);
            this.selectProduct(payload);
            this.productDialog = false;
        },
        openTable(){
            this.newDialog = true;
        },
        updateProspect(payload) { 
            let index = this.prospects.findIndex(x => x.id == payload.id);
            this.prospects[index] = payload;
            this.selectProspect(payload);
            this.prospectDialog = false;        
        },
        saveProspect(payload) {
            this.prospects.push(payload);
            this.selectProspect(payload);
            this.prospectDialog = false;
        },
        saveCustomer(payload) {
            this.customers.push(payload);
            this.selectCustomer(payload);
            this.customerDialog = false;
        },
        async selectQuotation(payload) {
            console.log('payload', payload),
            this.entity = fillObject(this.entity, payload);
            this.entity.id_customer ?? 0 > 0 ? this.is_customer = true : this.is_customer = false
            this.entity.prospect_name = payload.prospect;
            this.entity.date = new Date(this.entity.date);
            this.entity.expiration_date = new Date(this.entity.expiration_date);
        },
        deleteItem(item) {
            try {
                if (item.id) throw 'No se puede eliminar una partida ya guardada';

                let id_number = item.id_number;
                this.entity.items = this.entity.items.filter((x) => x.id_number != item.id_number);
                this.entity.items.forEach((x) => {
                    x.id_number = x.id_number > id_number ? x.id_number - 1 : x.id_number;
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        editItem(item) {
            this.item = fillObject(this.item, item);
            this.scrollToTop();
        },
        selectItem(item) {
            this.Quotation = fillObject(this.Quotation, item);
            console.log('Cotizacion items: ',this.item);
        },
        selectProspect(payload) {
            this.entity.id_crm_prospect = payload.id;
            this.entity.prospect_name = payload.prospect_name;
        },
        selectProduct(products) {
            this.item.key_name = products.key_name;
            this.item.description = products.name;
            this.item.id_concept = products.id;
            this.item.price = products.unit_price;
        },
        selectCustomer(customers){
            this.entity.id_customer = customers.id;
            this.entity.customer_name = customers.name;
            this.entity.customer_rfc = customers.rfc;
            this.entity.customer_address = customers.address + ' ' + customers.suburb + ', ' + customers.municipality + ', ' + customers.state + ', ' + customers.country;
            this.entity.customer_email = customers.email;
            this.entity.customer_phone = customers.phone;
        },
        saveDetail() {
            try {
                this.validateItem = validate(this.item, this.rulesItem);
                if (!this.validateItem.valid) {
                    throw "Favor de validar los campos";
                }
                if (this.entity.status == 3) {
                    throw "La cotizacion ya cuenta con pedido"
                }
                this.item.fillSession(this.session);
                let item = { ...this.item};
                if (item.id_number) {
                    let index = this.entity.items.findIndex(x => x.id_number == this.item.id_number);
                    this.entity.items[index] = item;
                }else {
                    item.id_number = item.id_number ?? this.entity.items.length + 1;
                    this.entity.items.push(item);
                }
                this.item = new QuotationItem(this.session);
                this.$refs.quantity.focus();
                console.log(this.entity);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        openNew() {
            this.entity = new Quotation(this.session);
            this.is_customer = false;
        },
        opcion(entity) {
            this.entity = fillObject(this.entity, entity);
            this.confirmDialog = true;
        },
        convertirPedido(entity) {
            this.entity = fillObject(this.entity, entity);
            this.confirmDialog = true;
        },
        async pedido() {
            this.loading = true;
            try {
                if (this.entity.status == null && this.entity.id == 0) {
                throw "La cotizacion debe estar registrada o se encuentra con pedido"
                }
                if (this.entity.status == 3) {
                    this.confirmDialog = false;
                    throw "La cotizacion ya cuenta con pedido"
                }
                this.confirmDialog = false;
                this.entity.status = 3;
                this.order.id_customer = this.entity.id_customer;
                this.order.items = this.entity.items;
                this.order.status = this.entity.status;
                console.log(this.order);
                console.log(this.entity);

                this.entity.order = await this.entity.pedido();
                this.order = new Order(this.session);
                
                this.$toast.add(
                    new Toast({
                        summary: "Pedido",
                        detail: "Pedido Generado",
                    })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async generateOption() {
                this.loading = true;
            try {
                if (this.entity.id == 0) {
                    throw "Seleccione una cotizacion"
                }
                alert('Generar Opcion' + this.options)
                this.entity.id = null;
                    let entity = await this.entity.save();
                    this.$emit('save', entity);
                    this.quotations.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Se genero la alternativa: " + this.option,
                        })
                    );
                this.confirmDialog = false;
            }
            catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async copy() {
            this.entity.id = null;
            let entity = await this.entity.save();
            //* Agregamos un dato extra
            this.$emit('save', entity);
            this.quotations.push(entity);
            this.$toast.add(
            new Toast({
                summary: "Creacion",
                detail: "Informacion guardada con exito",
            })
            );
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    this.entity.total = this.item.total;
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    this.$emit('update', entity);
                    //* Modificamos el listado pah
                    let index = this.quotations.findIndex((x) => x.id == this.entity.id);
                    this.quotations[index] = entity;
                } else {
                    this.entity.total = this.item.total;
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity);
                    this.quotations.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.entity = new Quotation(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.$refs.nombre.focus();
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.quotations = this.quotations.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Quotation(this.session);
                this.$toast.add({
                    severity: "success",
                    summary: "Eliminacion",
                    detail: "Registro eliminado con exito",
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                this.quotations = await this.entity.all();
                this.prospects = await new Prospect(this.session).all();
                this.customers = await new Customer(this.session).all();
                this.products = await new InventoryArticle(this.session).all();
                console.log(this.entity);
            if (this.id) {
                this.entity.id = this.id;
                let entity = await this.entity.retrieve();
                this.entity = fillObject(this.entity, entity);
            }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>
  
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
  