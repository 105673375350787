import {
    Abstract
} from './Abstract';

export class QuotationItem extends Abstract {
    constructor(session) {
        super('crm/Quotation', session);
        this.id_crm_quotation = null;
        this.description = null;
        this.quantity = null;
        this.price = null;
        this.total = null;
    }
}